import {Component, Input, OnInit, AfterViewInit} from '@angular/core';
import {DomSanitizer, SafeHtml} from '@angular/platform-browser';
import {
  NgbAccordionBody,
  NgbAccordionButton,
  NgbAccordionCollapse,
  NgbAccordionHeader, NgbAccordionItem, NgbModal
} from "@ng-bootstrap/ng-bootstrap";
import {TranslateModule} from "@ngx-translate/core";
import {JsonPipe} from "@angular/common";
import {STEP_STATUS} from "../../../models/enums";
import {RagRepository} from "../../../repositories/rag.repository";
import {ActivatedRoute, Router} from "@angular/router";
import {MarkdownComponent} from "ngx-markdown";
import {Utilities} from "../../../utilities/utilities";
import {PartitionViewComponent} from "../../partitionComponents/partition-view/partition-view.component";
import {DocumentRepository} from "../../../repositories/document.repository";

declare var bootstrap: any;

@Component({
  selector: 'app-rag-final-answer',
  standalone: true,
  imports: [
    NgbAccordionBody,
    NgbAccordionButton,
    NgbAccordionCollapse,
    NgbAccordionHeader,
    NgbAccordionItem,
    TranslateModule,
    JsonPipe,
    MarkdownComponent
  ],
  templateUrl: './rag-final-answer.component.html',
  styleUrl: './rag-final-answer.component.css',
  styles: [`
    markdown ::ng-deep h1 {
      font-size:19px;
      font-wight:bold;
    }
    markdown ::ng-deep h2,
    markdown ::ng-deep h3,
    markdown ::ng-deep h4 {
      font-size: 16px;
      font-weight: bold;
    }
  `]
})
export class RagFinalAnswerComponent {
  @Input() step: any;
  processedText: SafeHtml | undefined;
  protected readonly STEP_STATUS = STEP_STATUS;
  chatId: string | null = "";

  constructor(private documentService: DocumentRepository,private modalService: NgbModal,private route: ActivatedRoute, private router: Router,private sanitizer: DomSanitizer) {
  }

  async ngOnInit(): Promise<void> {
    this.route.paramMap.subscribe(params => {
      this.chatId = params.get('chatId');
    });
    this.replaceGuids(this.step.action?.rawData).then(safeHtml => {
      this.processedText = safeHtml;
    });

  }
  async replaceGuids(text: string): Promise<SafeHtml> {
    const guidRegex = /\[([0-9a-fA-F-]{36})\]/g;
    const matches = Array.from(text.matchAll(guidRegex));
    const guidMap: Record<string, string> = {};
    for (const match of matches) {
      const guid = match[1];
      if (!guidMap[guid]) {
        try {
          const matchingReference: any = this.getDataForGuid(guid);
          const document = await this.documentService.getDocument(matchingReference.documentId).toPromise();

          if (document && document.displayName) {
            guidMap[guid] = document.displayName;
          } else {
            console.warn(`Document not found or missing displayName for GUID: ${guid}`);
            guidMap[guid] = guid;
          }
        } catch (error) {
          console.error(`Error fetching document for GUID: ${guid}`, error);
          guidMap[guid] = guid;
        }
      }
    }
    const processedText = text.replace(guidRegex, (match, guid) => {
      const displayName = guidMap[guid] || guid;
      return `<span class="clickable-guid guid-${guid}" style="cursor: pointer;">[${displayName}]</span>`;
    });

    return this.sanitizer.bypassSecurityTrustHtml(processedText);
  }
  onGuidClick(event: MouseEvent): void {
    const target = event.target as HTMLElement;
    if (target && target.classList.contains('clickable-guid')) {
      const classList = Array.from(target.classList);
      const guidClass = classList.find((cls) => cls.startsWith('guid-'));
      if (guidClass) {
        const guid = guidClass.slice(5); // Remove the 'guid-' prefix
        this.handleClick(guid);
      } else {
        console.error('GUID class not found');
      }
    }
  }

  async handleClick(guid: string): Promise<void> {
    console.log('GUID clicked:', guid);
    const matchingReferece: any = this.getDataForGuid(guid);
    const tooltipDataRaw = this.documentService.getDocumentPartitionDetails(matchingReferece.documentId, matchingReferece.partitionId).subscribe(partition => {
      this.documentService.getDocument(matchingReferece.documentId).subscribe(document => {
        this.openDialog(partition,document);});
    });

  }

  openDialog(toolTipData:any,document: any) {

    const modalRef = this.modalService.open(PartitionViewComponent, {size: 'lg'});
    modalRef.componentInstance.partition= toolTipData;
    modalRef.componentInstance.document= document;
    modalRef.result.then((result) => {
      if (result) {
      }
    }).catch((error) => {
      console.error('Modal dismissed', error);
    });
  }
  getDataForGuid(guid: string): string {
    if (this.step?.action?.references) {    console.log(this.step?.action?.references);
      const matchingReference = this.step.action.references.find((ref: any) => ref.partitionId === guid);
      if (matchingReference) {
        return matchingReference;
      }
    }
    return 'No matching reference found for GUID';
  }

  backToChat() {
    this.router.navigate([`/chat/${this.chatId}`]);
  }


}
