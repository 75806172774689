<div class="modal-header">
  <h5 class="modal-title">{{"document.uploadFile" | translate}}</h5>
  <button type="button" class="close" aria-label="Close" (click)="onClose()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <form [formGroup]="uploadForm" (ngSubmit)="onSubmit()">
    <div class="form-group">
      <label for="displayName">{{"document.displayName" | translate}}</label>
      <input type="text" id="displayName" class="form-control" formControlName="displayName" required />
    </div>
    <div class="form-group">
      <label for="description">{{"document.description" | translate}}</label>
      <textarea id="description" class="form-control" formControlName="description" required></textarea>
    </div>
    <div>
      <label for="virtual">{{"document.virtual" | translate}}</label>
      <input type="checkbox" id="virtual" class="custom-checkbox ml-2" formControlName="type"  (change)="toggleFileUpload()"/>
    </div>

    <div class="form-group" [ngClass]="{'d-none':isVirtual===true}">
      <label for="file">File</label>
      <input type="file" id="file" class="form-control-file" (change)="onFileSelected($event)" [ngClass]="{'required':isVirtual===true}"/>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-secondary" (click)="onClose()">{{"general.cancel" | translate}}</button>
      <button type="submit" class="btn btn-primary" [disabled]="!uploadForm.valid">{{"general.upload" | translate}}</button>
    </div>
  </form>
</div>
