import {Component, Input, OnInit} from '@angular/core';
import {TranslateModule} from "@ngx-translate/core";
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {JsonPipe, NgIf} from "@angular/common";
import {documentPartitionType} from "../../../models/enums";
import {Router} from "@angular/router";
import {MarkdownComponent} from "ngx-markdown";

@Component({
  selector: 'app-partition-view',
  standalone: true,
  imports: [
    TranslateModule,
    NgIf,
    JsonPipe,
    MarkdownComponent
  ],
  templateUrl: './partition-view.component.html',
  styleUrl: './partition-view.component.css'
})
export class PartitionViewComponent implements OnInit {
  @Input() partition!: any;
  @Input() document?: any;
  base64Image: string = "";

  constructor(public activeModal: NgbActiveModal, private router: Router) {
  }

  ngOnInit(): void {
    if (this.partition.elementType == documentPartitionType.FIGURE) {
      this.base64Image = `data:image/jpeg;base64,${this.partition.rawData}`;
    }
  }

  onClose(): void {
    this.activeModal.close();
  }

  protected readonly documentPartitionType = documentPartitionType;

  goToDocument(documentId:string ) {
    const url = `documents/${documentId}`;
    window.open(url, '_blank');
  }
}
