@if (step?.action) {
  <div class=" mt-3">
    @if (step.stepStatus == STEP_STATUS.USER_PENDING) {
      <div class="d-flex justify-content-end w-100">
        <button class="btn btn-outline-dark mb-2" (click)="addItem()">+</button>
      </div>
      <div cdkDropList (cdkDropListDropped)="drop($event)" class="list-group">
        @for (item of step?.action?.planSteps; track item; ) {
          <div class="list-group-item d-flex justify-content-between align-items-center" cdkDrag>
            <div class="d-flex align-items-center" *ngIf="!item.isEditing">
              @if (step.stepStatus == STEP_STATUS.USER_PENDING && !item.isEditing) {
                <i class="bi bi-grip-vertical me-2" cdkDragHandle></i>
              }
              {{ item.rawData }}
            </div>
            <div class="d-flex align-items-center w-100" *ngIf="item.isEditing">
              <i class="bi bi-grip-vertical me-2" cdkDragHandle></i>
              <textarea [(ngModel)]="item.rawData" class="form-control me-2 w-100"></textarea>
            </div>
            <div>
              @if (step.stepStatus == STEP_STATUS.USER_PENDING && !item.isEditing) {
                <i class="bi bi-pencil" (click)="editItem(item)" style="cursor: pointer;"></i>
                <i
                  class="bi bi-x text-danger ml-1 "
                  (click)="removeItem($index)"
                  style="cursor: pointer; font-size: 1.5rem; "
                  title="Delete"
                ></i>
              }
              @if (item.isEditing) {
                <div>
                  <i class="bi bi-check" (click)="saveItem(item, $index)"
                     style="cursor: pointer; margin-right: 5px;"></i>
                  <i class="bi bi-x" (click)="discardEdit(item)" style="cursor: pointer;"></i>
                </div>
              }
            </div>
          </div>
        }
      </div>

      <button type="submit" class="btn btn-primary me-2 mt-2" (click)="next()">Next</button>
    } @else {
      <div class="list-group">
        @for (item of step?.action?.planSteps; track item; ) {
          <div class="list-group-item d-flex justify-content-between align-items-center">
            <div class="d-flex align-items-center">
              {{ item.rawData }}
            </div>
          </div>
        }
      </div>
    }
  </div>

}
