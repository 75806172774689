import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
  BaseRepository,
  PositoryErrorHandlerService,
  Path,
  RepoPath
} from 'ngpository';
import {FormDataService} from "../services/form-data.service";
import {map} from "rxjs";
import {document, documents, Partition, Partitions} from "../models/documentModel";
import {environment} from "../../environments/environment";

@RepoPath(environment.documentServiceUrl)
@Injectable({
  providedIn: 'root'
})
export class DocumentRepository extends BaseRepository<any>{
  static showLoader: boolean = true;

  constructor(httpClient: HttpClient, errorhandler: PositoryErrorHandlerService, private formDataConverter:FormDataService) {
    super(httpClient, errorhandler);
  }

  @Path('/')
  getDocuments() {
    return super.get().pipe(
      map(data => new documents(data)));
  }
  @Path('/{documentId}')
  getDocument(documentId: string | null) {
    return super.get().pipe(
      map(data => new document(data)));
  }
  @Path('/{documentId}/partitions')
  getDocumentPartition(documentId: string | null) {
    return super.get().pipe(
      map(data => new Partitions(data)));
  }
  @Path('/{documentId}/partitions/{partitionId}')
  getDocumentPartitionDetails(documentId: string | null,partitionId: string | null) {
    return super.get();
  }
  @Path('/{documentId}/partitions/{partitionId}')
  patchPartition(documentId: string | null,partitionId: string | null,elementType:string, rawData: string, summary: string = "", reEmbed: boolean= true, ) {
  /* const formData: FormData = new FormData();
    formData.append('elementType',elementType);
    formData.append('rawData', rawData);
    formData.append('reEmbed', reEmbed.toString());
    formData.append('summary', summary);*/
    let content = {
      elementType:elementType,
      rawData:rawData,
      reEmbed:reEmbed.toString(),
      summary:summary
    }
    return super.patch(content);
  }
  @Path('/{documentId}/partitions')
  addPartition(documentId: string | null, elementType:string, rawData: string, summary: string = "", reEmbed: boolean= true, ) {
    let content = {
      elementType:elementType,
      rawData:rawData,
      reEmbed:reEmbed.toString(),
      summary:summary
    }
    return super.post(content);
  }
  @Path('/')
  save(file: File | null, force: boolean = false,displayName: String="", description: String ="") {
    const formData: FormData = new FormData();
    let metaData={displayName: displayName, description:description};
    formData.append('metadata', JSON.stringify(metaData));
    if(file) {
      formData.append('file', file, file.name);
    }
    formData.append('force', force.toString());
    return super.postFile(formData);
  }
  @Path('/{documentId}')
  deleteItem(documentId:string) {
    return super.delete()
  }
  @Path('/{documentId}/partitions/{partitionId}')
  deletePartition(documentId: string | null, partitionId: string) {
    return super.delete()
  }

  getDownloadfile(documentId: String) {
    const url = `${environment.serviceUrl}/${environment.documentServiceUrl}/${documentId}/preview`;
    return this.httpClient.get(url, { responseType: 'blob' });
    //return super.get((res: any) => {return res},{ responseType: 'blob' });
  }

  public setUrl(url:string) {
    const regExp = /https?:\/\/.*/gm;
    url = regExp.test(url) ? url : 'http://' + url;
    this.repoUrl = url;

  }
  public unSetUrl(){
    this.repoUrl = undefined;
  }



}
