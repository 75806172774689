import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpRequest} from '@angular/common/http';
import {from, Observable, of, switchMap, throwError} from 'rxjs';
import {catchError, finalize} from 'rxjs/operators';
import {LoaderService} from '../services/loader.service';
import {LanguageService} from '../services/language.service';
import {ErrorHandlerService} from '../services/error-handler.service';
import {KeycloakService} from '../auth/keycloak.service';


@Injectable({
  providedIn: 'root'  // Ensures a single instance throughout the app
})
export class LoaderInterceptor {

  constructor(public loaderService: LoaderService,
              private errorHandlerService: ErrorHandlerService,
              private keycloakService: KeycloakService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
console.log(req.url);
   if(!req.url.includes('rag') && !req.url.includes('chats') && !req.url.includes('planner')){
     this.loaderService.show();
   }


    return from(this.keycloakService.getToken()).pipe(
      switchMap(token => {
        let headers = req.headers.set('Accept-Language', LanguageService.getClientLanguage() + '-HU');
        if (token) {
          console.log("token added");
          console.log(token);
          headers = headers.set('Authorization', 'Bearer ' + token);
        }

        const clonedRequest = req.clone({ headers: headers });
        return next.handle(clonedRequest);
      }),
      finalize(() => {this.loaderService.hide(); console.log("request finished")}),
      catchError(err => {
       // this.errorHandlerService.handleError(err);
        return throwError(err);
      })
    );
  }

}
