import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {ReactiveFormsModule} from "@angular/forms";
import {TranslateModule} from "@ngx-translate/core";
import {JsonPipe} from "@angular/common";

@Component({
  selector: 'app-view-plugin',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    TranslateModule,
    JsonPipe
  ],
  templateUrl: './view-plugin.component.html',
  styleUrl: './view-plugin.component.css'
})

export class ViewPluginComponent implements OnInit{
  @Input() plugin!: any;
  constructor(public activeModal: NgbActiveModal) {
  }

  ngOnInit(): void {
  }

  onClose(): void {
    this.activeModal.close();
  }
}
