import { Injectable, NgZone } from '@angular/core';
import { from, Observable, switchMap } from 'rxjs';
import { KeycloakService } from '../auth/keycloak.service';
import { EventSourcePolyfill } from 'event-source-polyfill';

@Injectable({
  providedIn: 'root'
})
export class ChatStreamingService {
  constructor(
    private keycloakService: KeycloakService,
    private ngZone: NgZone
  ) {}

  connect(url: string): Observable<string> {
    return from(this.keycloakService.getToken()).pipe(
      switchMap(token => {
        return new Observable<string>(observer => {
          const eventSource = new EventSourcePolyfill(url, {
            headers: {
              'Authorization': `Bearer ${token}`
            }
          });

          eventSource.onmessage = event => {
            this.ngZone.run(() => {
              // Emit the raw data
              observer.next(event.data);
            });
          };

          eventSource.onerror = error => {
            this.ngZone.run(() => {
              observer.error(error);
            });
          };

          // Cleanup on unsubscription
          return () => {
            eventSource.close();
          };
        });
      })
    );
  }
}
