<div class="modal-header">
  @if(document?.displayName){
    <h5 class="modal-title">{{document.displayName}}</h5>
  } @else {
    <h5 class="modal-title">{{"document.partition."+partition.elementType | translate}}</h5>
  }

  <button type="button" class="close" aria-label="Close" (click)="onClose()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  @if(partition.elementType==documentPartitionType.TEXT){
    <markdown [innerHTML]="partition.rawData"></markdown>
  }
  @if(partition.elementType==documentPartitionType.FIGURE){
    <div class="d-flex justify-content-center">
      <div *ngIf="base64Image" class="card" style="width:100%;">
        <img [src]="base64Image" class="card-img-top" alt="Image">
      </div>
    </div>
  }
  <h5 class="modal-title mt-2">{{'document.partition.summaryTitle' | translate}}</h5>
  <markdown [innerHTML]="partition.semanticSummary"></markdown>
  <div class="modal-footer">
    @if(document){
      <button type="button" class="btn btn-secondary" (click)="goToDocument(document.documentId)">{{"rag.goToDocument" | translate}}</button>
    }
  </div>
</div>
