import {HttpErrorResponse} from '@angular/common/http';
import {throwError} from 'rxjs';
import {environment} from '../../environments/environment';
import {AvailableUserChoices} from "../models/enums";

export class Utilities {
  static isProduction() {
    return environment.production;
  }

  static handleError(error: HttpErrorResponse) {
    let errorMessage = 'Unknown error!';
    if (error.error instanceof ErrorEvent) {
      // Client-side errors
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // Server-side errors
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    return throwError(errorMessage);
  }


  static getServiceUrl() {
    return environment.serviceUrl;
  }

  static convertTypeToUrl(tool: AvailableUserChoices) {
    return tool.toLowerCase().replace(/_/g, '-');
  }
  static convertFileToBase64(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = error => reject(error);
    });
  }

  static convertFileToBinaryString(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsArrayBuffer(file);
      reader.onload = () => {
        const binary = btoa(
          new Uint8Array(reader.result as ArrayBuffer)
            .reduce((data, byte) => data + String.fromCharCode(byte), "")
        );
        resolve(binary);
      };
      reader.onerror = error => reject(error);
    });
  }
  static getRepoPath(basePath: string): string {
    return `${environment.serviceUrl}${basePath}`;
  }

  static escapeHtml(text: string): string {
    const map: { [key: string]: string } = {
      '&': '',
      '<': '',
      '>': '',
      '"': '',
      "'": '',

    };
    return text.replace(/[&<>"'\n]/g, (m) => map[m]);
  }
  static generateGUID(): string {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      const r = (Math.random() * 16) | 0;
      const v = c === 'x' ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  }
}
