<div class="row mt-3 mr-3" *appHasRole="'copilot-admin-role'">
  <div class="col-12 text-right">
      <button class="btn btn-primary" (click)="openUploadDialog()">{{ 'document.add' | translate }}</button>
  </div>
</div>
<div style="margin:30px" class="tableContainer">

  <table *ngIf="this.documentList" datatable [dtOptions]="dtOptions" class="row-border hover">
    <thead>
    <tr>
      <th>{{"document.displayName" | translate}}</th>
      <th>{{"document.size" | translate}}</th>
      <th>{{"document.status" | translate}}</th>
      <th>{{"document.created" | translate}}</th>
      <th></th>
    </tr>
    </thead>
    <tbody>
    <tr  *ngFor="let document of this.documentList?.documents">
      <td class="font-weight-bold"><a [routerLink]="['/documents/'+document.documentId]" >{{ document.displayName }}
      @if(document.type==documentTypeEnum.CUSTOM){
        (<i class="bi bi-headset-vr"></i>)
      }
      </a></td>
      <td>{{ document.size | toKb}} Kb</td>
      <td>{{ document.status }}</td>
      <td>{{ document.createdOn | date:'yyyy-MM-dd HH:mm:ss'}}</td>
      <td>
        <fa-icon *appHasRole="'copilot-admin-role'" [icon]="faTrash" (click)="showSwal( document.documentId )" ></fa-icon></td>
    </tr>
    </tbody>
  </table>

</div>
