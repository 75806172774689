import {Component} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import {FormBuilder, FormGroup, ReactiveFormsModule, Validators} from '@angular/forms';
import {DocumentRepository} from "../../repositories/document.repository";
import {TranslateModule} from "@ngx-translate/core";
import {NotificationService} from "../../services/notification.service";
import {Router} from "@angular/router";
import {NgClass} from "@angular/common";
import {STEP_TYPE} from "../../models/enums";


@Component({
  selector: 'app-upload-document',
  standalone: true,
  imports: [ReactiveFormsModule, TranslateModule, NgClass],
  templateUrl: './upload-document.component.html',
  styleUrl: './upload-document.component.css',

})
export class UploadDocumentComponent {
  uploadForm: FormGroup;
  selectedFile: File | null = null;
  isVirtual: boolean = false;
  constructor(
    public activeModal: NgbActiveModal,
    private docRepository: DocumentRepository,
    private fb: FormBuilder,
    private notificationService: NotificationService,
    private router: Router
  ) {
    this.uploadForm = this.fb.group({
      displayName: ['', Validators.required],
      description: ['', Validators.required],
      type:[false],
      file: [null]
    });
  }

  onFileSelected(event: any): void {
    this.selectedFile = event.target.files[0];
    this.uploadForm.patchValue({ file: this.selectedFile });
  }

  onSubmit(): void {
    if (this.uploadForm.valid) {
      const { displayName, description } = this.uploadForm.value;
      this.docRepository.save(this.selectedFile,true, displayName, description).subscribe(
        (response) => {
          console.log('Upload successful', response);
          this.notificationService.showSuccess("Siker","document.successUpload");
          this.activeModal.close(true);
          this.router.navigate([this.router.url]).then(() => {
            window.location.reload();
          });
        },
        (error) => {
          console.error('Upload failed', error);
        }
      );
    }
  }

  onClose(): void {
    this.activeModal.close();
  }
  toggleFileUpload(): void {
    this.setFileValidators();
    this.isVirtual = this.uploadForm.get('type')?.value || false;
  }

  setFileValidators(): void {
    const fileControl = this.uploadForm.get('file');
    if (this.uploadForm.get('type')?.value === false) {
      fileControl?.setValidators(Validators.required);
    } else {
      fileControl?.clearValidators();
    }
    fileControl?.updateValueAndValidity();
  }

  protected readonly STEP_TYPE = STEP_TYPE;

}
