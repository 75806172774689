import { Component, Input, OnInit, ChangeDetectorRef, NgZone } from '@angular/core';
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { PdfViewerModule } from "ng2-pdf-viewer";
import { NgIf } from "@angular/common";
import { TranslateModule } from "@ngx-translate/core";
import { DocumentRepository } from "../../repositories/document.repository";

@Component({
  selector: 'app-pdf-viewer',
  standalone: true,
  imports: [PdfViewerModule, NgIf, TranslateModule],
  templateUrl: './pdf-viewer.component.html',
  styleUrls: ['./pdf-viewer.component.css']
})
export class PdfViewerComponent implements OnInit {
  @Input() documentId!: string;
  @Input() page: number = 1;
  documentUrl?: Uint8Array;
  error: boolean = false;
  private tempBlob: Blob = new Blob();
  private contentType = "";


  constructor(
    public activeModal: NgbActiveModal,
    private documentRepository: DocumentRepository,
    private cdr: ChangeDetectorRef,
    private ngZone: NgZone
  ) {}
  ngOnInit(): void {
    console.log(this.documentId);
    if (!this.documentId) {
      this.error = true;
    } else {
      this.downloadFile(this.documentId);
    }
  }

  downloadFile(documentId: string): void {
    // Fetching Data File
    this.documentRepository.getDownloadfile(documentId).subscribe(
      (retFileData: any) => {
        this.tempBlob = new Blob([retFileData], { type: this.contentType });
        const fileReader = new FileReader();
        fileReader.onload = () => {
          this.ngZone.run(() => {
            this.documentUrl = new Uint8Array(fileReader.result as ArrayBuffer);
            this.cdr.detectChanges(); // Manually trigger change detection
          });
        };
        fileReader.readAsArrayBuffer(this.tempBlob);
      },
      (err: Error) => {
        console.error('Error fetching file:', err);
        this.ngZone.run(() => {
          this.error = true;
          this.cdr.detectChanges(); // Manually trigger change detection
        });
      }
    );
  }

  handlePdfError(error: any): void {
    console.error('PDF loading error:', error);
    this.ngZone.run(() => {
      this.error = true;
      this.cdr.detectChanges(); // Manually trigger change detection
    });
  }
}
