<div class="position-relative">
  <markdown
    [innerHTML]="processedText"
    class="p-3 mb-3"
    (click)="onGuidClick($event)">
  </markdown>
  @if(step.stepStatus== STEP_STATUS.AUTO_FINALIZED){
    <p class="mt-2"><button type="submit" class="btn btn-primary me-2"  (click)="backToChat()">Back to chat</button></p>
  }
</div>
