import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, map } from 'rxjs';
import { KeycloakService } from './keycloak.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(private keycloakService: KeycloakService, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    const requiredRoles = route.data['roles'] as string[] || [];

    return this.keycloakService.isLoggedIn().pipe(
      map(authenticated => {
        if (!authenticated) {
          console.log("authenticate false")
          this.router.navigate(['/login']);
          return false;
        }
        if (requiredRoles.length > 0) {
          const userRoles = this.keycloakService.getUserRoles();
          const hasRequiredRole = requiredRoles.some(r => userRoles.includes(r));
          if (!hasRequiredRole) {
            this.router.navigate(['/no-access']);
            return false;
          }
        }

        return true;
      })
    );
  }
}
