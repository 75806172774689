import {Component, EventEmitter, Input, Output} from '@angular/core';
import {CdkDragDrop, DragDropModule, moveItemInArray} from "@angular/cdk/drag-drop";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {NgFor, NgIf} from "@angular/common";
import {PlannerRepository} from "../../../repositories/planner.repository";
import {ActivatedRoute} from "@angular/router";
import {STEP_STATUS} from "../../../models/enums";
import {Utilities} from "../../../utilities/utilities";

@Component({
  selector: 'app-revised-plan',
  standalone: true,
  imports: [DragDropModule,ReactiveFormsModule,NgFor,FormsModule,NgIf],
  templateUrl: './revised-plan.component.html',
  styleUrl: './revised-plan.component.css'
})
export class RevisedPlanComponent {
  items = [
    { id: 1, label: 'Item 1', isEditing: false },
    { id: 2, label: 'Item 2', isEditing: false },
    { id: 3, label: 'Item 3', isEditing: false },
    { id: 4, label: 'Item 4', isEditing: false },
    { id: 5, label: 'Item 5', isEditing: false }
  ];
  toolingId: string | null = "";
  tempData: string | null = "";
  @Input() step: any;
  @Output() loadTooling: EventEmitter<void> = new EventEmitter<void>();


  constructor(private plannerRepository: PlannerRepository,private route: ActivatedRoute) {}
  async ngOnInit(): Promise<void> {
    this.route.paramMap.subscribe(params => {
      this.toolingId = params.get('plannerId');
    });
  }
  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.step.action.planSteps, event.previousIndex, event.currentIndex);
    this.step.action.planSteps[event.currentIndex].seq=event.currentIndex;
    this.step.action.planSteps[event.previousIndex].seq=event.previousIndex;
    console.log(event.previousIndex, event.currentIndex);

  }

  editItem(item: any) {
    this.tempData=item.rawData;
    item.isEditing = true;
  }

  saveItem(item: any,index: any) {
    delete item.isEditing;
  }
  removeItem(index: number) {
    this.step.action.planSteps.splice(index, 1);
  }
  discardEdit(item: any) {
    item.isEditing = false;
    item.rawData = this.tempData;
  }
  addItem() {
    const newItem = {
      planStepId: "",
      rawData: 'New Item',
      seq: this.step.action.planSteps.length
    };

    this.step.action.planSteps.push(newItem);
  }
  next() {
    this.step.stepStatus="USER_FINALIZED";
    if(this.toolingId) {
      this.plannerRepository.patchToolingStep(this.toolingId, this.step.toolingStepId, this.step).subscribe(
        async (toolingStepPatchResponse: any) => {
          this.loadTooling.emit();
        }
      );
    }
  }

  protected readonly STEP_STATUS = STEP_STATUS;
}
