<div style="margin:30px" class="mb-4">
  <div class="row d-flex align-items-stretch">
    <div class="col-md-12 col-12 ml-0 mt-2 pl-0">
      <div class="card h-100">
        <div class="card-body">
          <p class="card-title"><span class="font-weight-bold">{{ "document.displayName" | translate }}
            :</span> {{ documentElement?.displayName }}</p>
          <p class="card-text"><span class="font-weight-bold">{{ "document.status" | translate }}
            :</span> {{ documentElement?.status }}</p>
          @if (!documentElement?.type) {
          <p class="card-text"><span class="font-weight-bold"> {{ 'document.size' | translate }}
            :</span> {{ documentElement?.size }} </p>
          }
          <p class="card-text"><span class="font-weight-bold"> {{ 'document.description' | translate }}
            : </span> @if (documentElement?.description) {
            {{ documentElement?.description }}
          } @else {
            -
          }</p>
          <p class="card-text"><span class="font-weight-bold"> {{ 'document.abstract' | translate }}
            : </span> @if (documentElement?.abstract) {
            {{ documentElement?.abstract }}
          } @else {
            -
          }</p>

        </div>
      </div>
    </div>
    <div class="row mt-3 mr-3">
      <div class="col-12 text-right">
        @if (documentElement?.type==documentTypeEnum.UPLOADED) {
        <button class="btn btn-primary mr-2"
                (click)="openFile(documentElement.documentId)">{{ 'document.openPreview' | translate }}
        </button>
        <button class="btn btn-primary mr-2" (click)="openModal()">{{ 'document.openToC' | translate }}</button>
        }
        <button *appHasRole="'copilot-admin-role'" class="btn btn-primary" (click)="openUploadDialog()">{{ 'document.openUploadPartition' | translate }}
        </button>

      </div>
    </div>
    <div class="tableContainer mt-4">
      <table *ngIf="this.documentPartitions" datatable [dtOptions]="dtOptions" class="row-border hover">
        <thead>
        <tr>
          <th>{{ "document.partition.id" | translate }}</th>
          <th>{{ "document.partition.elementType" | translate }}</th>
          <th>{{ "document.partition.version" | translate }}</th>
          <th>{{ "document.partition.size" | translate }}</th>
          <th>{{ "document.partition.summary" | translate }}</th>
          <th></th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let partitionElement of documentPartitions?.partitions">
          <td class="font-weight-bold">{{ partitionElement.partitionId }}</td>
          <td>{{ partitionElement.elementType }}</td>
          <td>{{ partitionElement.version }}</td>
          <td>{{ partitionElement.size }}</td>
          <td>{{ partitionElement.semanticSummary | shorten }}</td>

          <td>
            @if (partitionElement.elementType == documentPartitionType.TEXT) {
              <fa-icon *appHasRole="'copilot-admin-role'" [icon]="faPenToSquare" (click)="openTextEditDialog(partitionElement)"></fa-icon>
              <fa-icon  [icon]="faEye" (click)="openViewDialog(partitionElement)"></fa-icon>
            } @else if (partitionElement.elementType == documentPartitionType.FIGURE) {
              <fa-icon *appHasRole="'copilot-admin-role'" [icon]="faPenToSquare" (click)="openImageEditDialog(partitionElement)"></fa-icon>
              <fa-icon  [icon]="faEye" (click)="openViewDialog(partitionElement)"></fa-icon>
            } @else if (partitionElement.elementType == documentPartitionType.TABLE) {
              <fa-icon [icon]="faEye" (click)="openTableViewDialog(partitionElement)"></fa-icon>
            }

            <fa-icon [icon]="faTrash"  *appHasRole="'copilot-admin-role'" (click)="delete( partitionElement.partitionId  )"></fa-icon>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
