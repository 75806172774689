import {Component} from '@angular/core';
import {faTrash} from '@fortawesome/free-solid-svg-icons';
import {CommonModule} from "@angular/common";
import {FontAwesomeModule} from "@fortawesome/angular-fontawesome";
import {DataTablesModule} from "angular-datatables";
import {Config} from "datatables.net";
import {DocumentRepository} from "../../repositories/document.repository";
import {lastValueFrom, Subject} from 'rxjs';
import {ToKbPipe} from "../../pipes/kb-to-mb.pipe";
import {TranslateModule} from "@ngx-translate/core";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import { UploadDocumentComponent } from '../../components/upload-document/upload-document.component';
import Swal from "sweetalert2";
import {Router, RouterLink} from "@angular/router";
import {HasRoleDirective} from "../../directives/has-role.directive";
import {documentTypeEnum, STEP_TYPE} from "../../models/enums";

@Component({
  selector: 'app-document-list',
  templateUrl: './document-list.component.html',
  styleUrl: './document-list.component.css',
  standalone: true,
    imports: [CommonModule, FontAwesomeModule, DataTablesModule, ToKbPipe, TranslateModule, RouterLink, HasRoleDirective],
})
export class DocumentListComponent {
  faTrash = faTrash;
  documentList: any=null;
  dtOptions: Config = {};
  dtTrigger: Subject<any> = new Subject();
  private dtElement: any;

  constructor(private docRepository: DocumentRepository,private modalService: NgbModal,private router: Router) {
  }

  async ngOnInit(): Promise<void> {
    this.docRepository.getDocuments().subscribe((res: any) => {
      this.documentList = res;
    });
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      processing: true
    };
    console.log(this.documentList);


  }
  ngAfterViewInit(): void {
    this.dtTrigger.subscribe(() => {
          console.log("table event")
      });
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  openUploadDialog(): void {
    const modalRef = this.modalService.open(UploadDocumentComponent, { size: 'lg' });

    modalRef.result.then((result) => {
      if (result) {
        console.log('File uploaded successfully');
      }
    }).catch((error) => {
      console.error('Modal dismissed', error);
    });
  }

  async deleteItem(docId: string) {
    await lastValueFrom(this.docRepository.deleteItem(docId));
  }

  showSwal(docId: string) {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-success",
        cancelButton: "btn btn-danger"
      },
      buttonsStyling: false
    });
    swalWithBootstrapButtons.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, cancel!",
      reverseButtons: true
    }).then(async (result) => {
      if (result.isConfirmed) {
        await this.deleteItem(docId)
        swalWithBootstrapButtons.fire({
          title: "Deleted!",
          text: "Your document has been deleted.",
          icon: "success"
        }).then(async (result)=> {
          if (result.isConfirmed) {
            this.router.navigate([this.router.url]).then(() => {
              window.location.reload();
            });
          }
        });
      } else if (
        /* Read more about handling dismissals below */
        result.dismiss === Swal.DismissReason.cancel
      ) {
        swalWithBootstrapButtons.fire({
          title: "Cancelled",
          text: "Your document is safe :)",
          icon: "error"
        });
      }
    });
  }

    protected readonly STEP_TYPE = STEP_TYPE;
  protected readonly documentTypeEnum = documentTypeEnum;
}
