<div class="modal-header">
    <h5 class="modal-title">{{"plugin.view" | translate}}</h5>
  <button type="button" class="close unhighlight" aria-label="Close" (click)="onClose()">
    <span aria-hidden="true">&times;</span>
  </button>

</div>
<div class="modal-body">
    <div class="row">
      <div class="col-12">
        <h5>{{"plugin.urlSection" | translate}}</h5>
      </div>
      <div class="col-md-12">
          <p>{{plugin.url}}</p>
      </div>
      <div class="col-12">
          <pre class="openApiSection">{{plugin.openapi}}</pre>
      </div>
    </div>
    <hr/>
    <div class="row">
      <div class="col-12">
        <h5>{{"plugin.manifestSection" | translate}}</h5>
      </div>
      <div class="col-md-6">
          <h5>{{"plugin.displayName" | translate}}</h5>
          <p>{{plugin.displayName}}</p>
      </div>
      <div class="col-md-6">
          <h5>{{"plugin.modelName" | translate}}</h5>
          <p>{{plugin.modelName}}</p>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <h5>{{"plugin.humanDescription" | translate}}</h5>
          <p>{{plugin.humanDescription}}</p>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <h5>{{"plugin.modelDescription" | translate}}</h5>
          <p>{{plugin.modelDescription}}</p>
        </div>
      </div>
    </div>
    <hr/>
    <div class="row">
      <div class="col-12">
        <h5>{{"plugin.authenticationSection" | translate}}</h5>
      </div>
      <div class="col-12">

        <p>{{plugin.authMethod}}</p>
      </div>
    </div>
    @if (plugin.authMethod=="mtls") {
      <div class="row">
        <div class="col-6">
            <h5>{{"plugin.certificatePublicKey" | translate}}</h5>
            <p>{{plugin.certificatePublicKey}}</p>
        </div>
        <div class="col-6">
          <h5>{{"plugin.certificatePrivateKey" | translate}}</h5>
          <p>{{plugin.certificatePrivateKey}}</p>
        </div>
      </div>}
    @if (plugin.authMethod=="auth_token") {
      <div class="row">
        <div class="col-6">
           <h3>{{"plugin.authHeader" | translate}}</h3>
          <p>{{plugin.authHeader}}</p>
        </div>
        <div class="col-6">
          <div class="form-group">
            <h5>{{"plugin.authToken" | translate}}</h5>
            <p>{{plugin.authToken}}</p>
          </div>
        </div>
      </div>
    }
    <div class="modal-footer">
      <button type="button" class="btn btn-secondary" (click)="onClose()">{{"general.cancel" | translate}}</button>
    </div>

</div>
