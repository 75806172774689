import {Component} from '@angular/core';
import {faTrash, faPenToSquare, faShareFromSquare, faEye} from '@fortawesome/free-solid-svg-icons';
import {CommonModule, NgFor} from "@angular/common";
import {FontAwesomeModule} from "@fortawesome/angular-fontawesome";
import {DataTablesModule} from "angular-datatables";
import {Config} from "datatables.net";
import {DocumentRepository} from "../../repositories/document.repository";
import {ActivatedRoute, Router} from "@angular/router";
import {TranslateModule} from "@ngx-translate/core";
import {ToKbPipe} from "../../pipes/kb-to-mb.pipe";
import {lastValueFrom, Subject} from "rxjs";
import {ShortenPipe} from "../../pipes/shorten.pipe";
import {NotificationService} from "../../services/notification.service";
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {PdfViewerComponent} from '../../components/pdf-viewer/pdf-viewer.component';
import Swal from "sweetalert2";
import {
  PartitionTextEditComponent
} from "../../components/partitionComponents/partition-text-edit/partition-text-edit.component";
import {Partition} from "../../models/documentModel";
import {
  PartitionImageEditComponent
} from "../../components/partitionComponents/partition-image-edit/partition-image-edit.component";
import {
  PartitionTableComponent
} from "../../components/partitionComponents/partition-table/partition-table.component";
import {TocViewerComponent} from "../../components/toc-viewer/toc-viewer.component";
import {AddPartitionComponent} from "../../components/partitionComponents/add-partition/add-partition.component";
import {documentPartitionType, documentTypeEnum} from "../../models/enums";
import {HasRoleDirective} from "../../directives/has-role.directive";
import {PartitionViewComponent} from "../../components/partitionComponents/partition-view/partition-view.component";

@Component({
  selector: 'app-document-detail-page',
  templateUrl: './document-detail-page.component.html',
  styleUrl: './document-detail-page.component.css',
  standalone: true,
    imports: [NgFor, CommonModule, FontAwesomeModule, DataTablesModule, TranslateModule, ToKbPipe, ShortenPipe, HasRoleDirective],
})
export class DocumentDetailPageComponent {
  constructor(private docRepository: DocumentRepository, private route: ActivatedRoute, private notificationService: NotificationService, private modalService: NgbModal, private router: Router) {
  }

  faTrash = faTrash;
  faEye = faEye;
  faPenToSquare = faPenToSquare;
  faShareFromSquare = faShareFromSquare;
  dtOptions: Config = {};
  dtTrigger: Subject<any> = new Subject();
  documentElement: any = null;
  documentPartitions: any = null
  documentId: string | null = null;
  private component: any;


  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      this.documentId = params.get('id');
      if (this.documentId) {
        this.docRepository.getDocument(this.documentId).subscribe((res: any) => {
          this.documentElement = res;
          console.log(this.documentElement);
        });
        this.docRepository.getDocumentPartition(this.documentId).subscribe((res: any) => {
          this.documentPartitions = res;
        });

        this.dtOptions = {
          pagingType: 'full_numbers',
          pageLength: 10,
          processing: true
        };
      }
    });
  }

  ngAfterViewInit(): void {
    this.dtTrigger.subscribe(() => {
      console.log("table event")
    });
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  openFile(documentId: any) {
    const modalRef = this.modalService.open(PdfViewerComponent, {size: 'xl'});
    modalRef.componentInstance.documentId = documentId;
  }

  async openTextEditDialog(partitionElement: Partition): Promise<void> {
    let partitionDetails = await this.getPartitionDetails(this.documentId, partitionElement.partitionId);
    this.openDialog(partitionDetails, partitionElement);
  }

  async openImageEditDialog(partitionElement: Partition): Promise<void> {
    let partitionDetails = await this.getPartitionDetails(this.documentId, partitionElement.partitionId);
    this.openDialog(partitionDetails, partitionElement);
  }

  openDialog(partitionDetails: any, partitionElement: any) {

    if(partitionElement.elementType==documentPartitionType.TEXT ){

      this.component= PartitionTextEditComponent;
    }
    else if(partitionElement.elementType==documentPartitionType.FIGURE){
      this.component=PartitionImageEditComponent;
    }
    else{
      this.component=PartitionTableComponent;
    }
    const modalRef = this.modalService.open(this.component, {size: 'lg'});
    modalRef.componentInstance.partition = partitionDetails;
    modalRef.componentInstance.documentId = this.documentId;
    modalRef.componentInstance.partitionId = partitionElement.partitionId;
    modalRef.result.then((result) => {
      if (result) {
        console.log('File uploaded successfully');
      }
    }).catch((error) => {
      console.error('Modal dismissed', error);
    });
  }
  openModal() {
    this.component= TocViewerComponent;
    const modalRef = this.modalService.open(this.component, {size: 'lg'});
    modalRef.componentInstance.toc = this.documentElement.tableOfContent;
    modalRef.result.then((result) => {
      if (result) {
      }
    }).catch((error) => {
      console.error('Modal dismissed', error);
    });
  }
  openUploadDialog() {
    this.component= AddPartitionComponent;

    const modalRef = this.modalService.open(this.component, {size: 'lg'});
    modalRef.componentInstance.documentId = this.documentId;
    modalRef.result.then((result) => {
      if (result) {
      }
    }).catch((error) => {
      console.error('Modal dismissed', error);
    });
  }
  async getPartitionDetails(documentId: string | null, partitionId: string) {
    let partitionDetails = await lastValueFrom(this.docRepository.getDocumentPartitionDetails(documentId, partitionId));
    return partitionDetails;
  }

  async deleteItem(documentId: string | null, partitionId: string) {
    await lastValueFrom(this.docRepository.deletePartition(documentId, partitionId));
  }

  delete(partitionId: string) {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-success",
        cancelButton: "btn btn-danger"
      },
      buttonsStyling: false
    });
    swalWithBootstrapButtons.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, cancel!",
      reverseButtons: true
    }).then(async (result) => {
      if (result.isConfirmed) {
        await this.deleteItem(this.documentId, partitionId);
        swalWithBootstrapButtons.fire({
          title: "Deleted!",
          text: "Your partition has been deleted.",
          icon: "success"
        }).then(async (result) => {
          if (result.isConfirmed) {
            this.router.navigate([this.router.url]).then(() => {
              window.location.reload();
            });
          }
        });
      } else if (
        /* Read more about handling dismissals below */
        result.dismiss === Swal.DismissReason.cancel
      ) {
        swalWithBootstrapButtons.fire({
          title: "Cancelled",
          text: "Your partition is safe :)",
          icon: "error"
        });
      }
    });
  }

  async openTableViewDialog(partitionElement: any) {
    let partitionDetails = await this.getPartitionDetails(this.documentId, partitionElement.partitionId);
    this.openDialog(partitionDetails, partitionElement);
  }

  protected readonly documentPartitionType = documentPartitionType;

  async openViewDialog(partitionElement: any) {
    let partitionDetails = await this.getPartitionDetails(this.documentId, partitionElement.partitionId);
    const modalRef = this.modalService.open(PartitionViewComponent , {size: 'lg'});
    modalRef.componentInstance.partition = partitionDetails;
  }

  protected readonly documentTypeEnum = documentTypeEnum;
}
