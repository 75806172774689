import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import {KeycloakService} from "../auth/keycloak.service";



@Directive({
  standalone: true,
  selector: '[appHasRole]'
})
export class HasRoleDirective {
  private requiredRole?: string;

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private authService: KeycloakService
  ) {}

  @Input() set appHasRole(role: string) {
    this.requiredRole = role;
    this.updateView();
  }

  private updateView() {
    this.viewContainer.clear();
    if (this.requiredRole && this.authService.hasRole(this.requiredRole)) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    }
  }
}
