<div class=" mt-3">
  <div class="row w-100">
    @if (step?.action.rawData) {
      <div class="col-12">
        <span class="badge bg-info ms-auto">{{ 'rag.searchQuery' | translate }}</span><span
        class="ml-2">{{ step?.action.rawData }} </span>
      </div>
    }
  </div>
  <div class=" mt-3">
    <div class="row">
      @for (reference of step?.action?.references; track reference) {
        <div class="col-12 d-flex">
          <div class="card mb-4 flex-fill">
            <div class="card-body d-flex flex-column">
              @if (documents[$index]){
              <h2 class="card-title">{{ documents[$index].displayName}}</h2>
              }
              <p>{{ 'rag.pageNumber' | translate }}: {{ reference.pageNumber }}, {{ 'rag.size' | translate }}: {{ reference.size }}</p>
             <markdown>{{ reference.summary }}</markdown>
              @if (reference.type== documentPartitionType.TEXT){
                <div class="markDownContent">
              <markdown >{{ reference.rawData }}</markdown>
                </div>
              }
              @else if (reference.type== documentPartitionType.TABLE){
                <div [innerHTML]="reference.rawData"></div>
              }
              @else if (reference.type== documentPartitionType.FIGURE){
                <div class="d-flex justify-content-center mb-4 mt-2">
                  <div class="card" style="width: 18rem;">
                    <img [src]="getBase64Image(reference.rawData)" class="card-img-top" alt="Image">
                  </div>
                </div>
              }
              <button class="btn btn-primary mt-auto" (click)="openFile(reference.documentId, reference.pageNumber)">Open preview</button>
            </div>
          </div>
        </div>
      }

    </div>
  </div>
  @if(step.stepStatus== STEP_STATUS.USER_PENDING){
    <button type="submit" class="btn btn-primary me-2"  (click)="next()">Next</button>
  }
</div>
