import { Component } from '@angular/core';
import { lastValueFrom, Subscription } from "rxjs";
import { ChatStreamingService } from "../../services/chat-stream.service";
import { JsonPipe, NgForOf, NgIf } from "@angular/common";
import { environment } from "../../../environments/environment";
import { ChatRepository } from "../../repositories/chat.repository";
import {KeycloakService} from "../../auth/keycloak.service";

@Component({
  selector: 'app-chat-stream-page',
  standalone: true,
  imports: [
    JsonPipe,
    NgForOf,
    NgIf
  ],
  templateUrl: './chat-stream-page.component.html',
  styleUrl: './chat-stream-page.component.css'
})
export class ChatStreamPageComponent {
  messages: any[] = [];
  private sseSubscription!: Subscription;
  chatId = '3c955e74-e7c0-4d22-8d53-33c08e9e85a6';
  messageId = '1fbf5c00-1abb-4df5-8a64-afadfec72c00';

  constructor(
    private chatStreamingService: ChatStreamingService,
    private chatRepository: ChatRepository, private keyCloakService: KeycloakService
  ) {}

  ngOnInit() {
    //this.sendMessage();

    console.log(this.keyCloakService.getUserRoles());
  }

  async sendMessage() {
    if (this.chatId) {
      try {
        const newMessage = await lastValueFrom(
          await this.chatRepository.newMessage(this.chatId, "what is a device in arrowhead? do not start rag!")
        );

        // Delay to ensure message is created server-side
        await new Promise(resolve => setTimeout(resolve, 2200));
        this.messageId = newMessage.nextMessageId;
        console.log('New messageId:', this.messageId);

        const sseUrl = `${environment.serviceUrl}${environment.chatServiceUrl}/${this.chatId}/messages/${this.messageId}/stream`;

        this.sseSubscription = this.chatStreamingService.connect(sseUrl).subscribe({
          next: (data) => {
            console.log('Received SSE data:', data);
          },
          error: (err) => {
            console.error('SSE error:', err);
          }
        });
      } catch (error) {
        console.error("Sending message failed:", error);
      }
    }
  }

  ngOnDestroy() {
    if (this.sseSubscription) {
      this.sseSubscription.unsubscribe();
    }
  }
}
