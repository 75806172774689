import {DocumentStatus, documentTypeEnum} from "./enums";


export class documents {
  documents: document[];

  constructor(data: any) {
    this.documents = data.documents.map((doc: any) => new document(doc));
  }
}

export class document {
  documentId: string;
  createdOn: Date;
  size: number;
  status: DocumentStatus;
  documentUrl: string;
  displayName: string;
  description: string;
  abstract: string;
  tableOfContent: string;
  type: documentTypeEnum;

  constructor(data: any) {
    this.documentId = data.documentId;
    this.createdOn = data.createdOn;
    this.size = data.size;
    this.status = data.status;
    this.documentUrl = data.documentUrl;
    this.displayName = data.displayName;
    this.description = data.description;
    this.abstract = data.abstract;
    this.tableOfContent = data.tableOfContent;
    this.type = data.type;
  }
}

export class Partitions {
  partitions: Partition[];

  constructor(data: any) {
    this.partitions = data.partitions.map((part: any) => new Partition(part));
  }
}

export class Partition {
  partitionId: string;
  elementType: string;
  version: number;
  size: number;
  semanticSummary: string;

  constructor(data: any) {
    this.partitionId = data.partitionId;
    this.elementType = data.elementType;
    this.version = data.version;
    this.size = data.size;
    this.semanticSummary = data.semanticSummary;
  }

}

export class PartitionFull {
  partitionId: string;
  version: number;
  elementType: string;
  size: number;
  coordinates: string;
  semanticSummary: string;
  vectorIndex: string;
  vectorId: string;
  vectorValue: string;
  rawData: string;

  constructor(data: {
    partitionId: string;
    version: number;
    elementType: string;
    size: number;
    coordinates: string;
    semanticSummary: string;
    vectorIndex: string;
    vectorId: string;
    vectorValue: string;
    rawData: string;
  }) {
    this.partitionId = data.partitionId;
    this.version = data.version;
    this.elementType = data.elementType;
    this.size = data.size;
    this.coordinates = data.coordinates;
    this.semanticSummary = data.semanticSummary;
    this.vectorIndex = data.vectorIndex;
    this.vectorId = data.vectorId;
    this.vectorValue = data.vectorValue;
    this.rawData = data.rawData;
  }
}

