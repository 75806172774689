import {APP_INITIALIZER, NgModule} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { KeycloakService } from './auth/keycloak.service';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import {LoginComponent} from "./pages/login/login.component";
import {HeaderComponent} from "./components/header/header.component";
import {DataTablesModule} from "angular-datatables";
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import {NgSelectModule} from "@ng-select/ng-select";
import {FormsModule} from "@angular/forms";
import {APP_BASE_HREF, CommonModule} from "@angular/common";
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from "@angular/common/http";
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import {LoaderService} from "./services/loader.service";
import {LoaderComponent} from "./components/loader/loader.component";
import {LoaderInterceptor} from "./interceptors/loader.interceptor";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import { ToastrModule } from 'ngx-toastr';
import {environment} from "../environments/environment";
import {ErrorHandlerService} from "./services/error-handler.service";
import {NotificationService} from "./services/notification.service";
import {NgPositoryModule} from "ngpository";
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ReactiveFormsModule } from '@angular/forms';
import {PdfViewerModule} from "ng2-pdf-viewer";
import {MarkdownModule} from "ngx-markdown";
import {HasRoleDirective} from "./directives/has-role.directive";

export function initializeKeycloak(keycloakService: KeycloakService): () => Promise<any> {
  return (): Promise<any> => keycloakService.initKeycloak();
}

@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    LoginComponent,
    HeaderComponent,
    LoaderComponent

  ],
  imports: [
    BrowserAnimationsModule,
    ToastrModule.forRoot({
      timeOut: 3000, // Default time before the toast disappears
      positionClass: 'toast-bottom-right', // Default position of toasts
      preventDuplicates: true, // Prevent duplicate toasts
    }),
    HttpClientModule,
    BrowserModule,
    DataTablesModule,
    AppRoutingModule,
    FontAwesomeModule,
    NgSelectModule,
    PdfViewerModule,
    FormsModule,
    NgbModule,
    ReactiveFormsModule,
    CommonModule,
    MarkdownModule.forRoot(),
    NgPositoryModule.forRoot({
      baseApiUrl: environment.serviceUrl,
      errorHandler: {useClass: ErrorHandlerService, deps: [NotificationService]},
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: httpTranslateLoader,
        deps: [HttpClient]
      }
    }),
    HasRoleDirective
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initializeKeycloak,
      multi: true,
      deps: [KeycloakService]
    },{
      provide: HTTP_INTERCEPTORS,
      useClass: LoaderInterceptor,
      multi: true
    },
    LoaderService,
    { provide: APP_BASE_HREF, useValue: environment.baseHref }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
export function httpTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json' );
}
